import axios from 'axios';
import { showAlert } from './alert';

export const sendMailClient = async (name,email,subject,message,tel) => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/article/sendMail',
            data: {
                name,
                email,
                subject,
                message,
                tel
                
            }
        });
        if (res.data.status === 'success') {
            showAlert('success', 'Mail Envoyé !');
            window.setTimeout(()=>{
                location.assign('/')
            },1500)
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};


