/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alert';

// type is either 'password' or 'data'
export const modifArticle = async (data,id) => {
    try {
        
        const url = `/api/v1/article/updateArticle/${id}`
            
        const res = await axios({
            method: 'PATCH',
            url,
            data,
        });
        
        if (res.data.status === 'success') {
            showAlert('success', `Article mis à jour!`);
            window.setTimeout(()=>{
                location.assign('/gestionArticles')
            },1500)
        }
    } catch (err) {
        showAlert('error', err);
    }
};

export const createArticle = async (data) => {
    try {
        
        const url = `/api/v1/article/createArticle`
            
        const res = await axios({
            method: 'POST',
            url,
            data,
        });
        
        if (res.data.status === 'success') {
            showAlert('success', `Création de votre article réussis !`);
            window.setTimeout(()=>{
                location.assign('/gestionArticles')
            },1500)
        }
    } catch (err) {
        showAlert('error', err);
    }
};

export const deleteArticle = async (id) => {
    try{
        const url = `/api/v1/article/deleteArticle/${id}`
        const res = await axios({
            method: 'PATCH',
            url
        });
        if (res.data.status === 'success') {
            showAlert('success', `Suppression de l'article réussie !`);
            window.setTimeout(()=>{
                location.assign('/gestionArticles')
            },1500)
        }
    }catch(err){
        showAlert('error', err);
    }
}

/* export const deleteImg = async (id,img) => {
    try{
        const url = `/api/v1/article/deletePhotoArticle/${id}/${img}`
        const res = await axios({
            method: 'PATCH',
            url
        });
        if (res.data.status === 'success') {
            showAlert('success', `Suppression de l'image réussie !`);
            window.setTimeout(()=>{
                location.assign(`/modificationArticle/${id}`)
            },1500)
        }
    }catch(err){
        showAlert('error', err);
    }
} */