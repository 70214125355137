import '@babel/polyfill';
import { login,forgotPass,logout,resetPassword } from './login';
import {updateSettings} from './modifInfos'
import {sendMailClient} from "./sendMail";
import {modifArticle,createArticle,deleteArticle,deleteImg} from "./gestionArticles"
import Quill from 'quill';
//import { resetPassword } from '../../controllers/authController';

const menuServices = document.getElementById('nav-services')
const menuListServices = document.querySelector('.deroulant__list')
const menu = document.querySelector('.navigation__nav')
const formClient = document.querySelector('.form')
const formLogin = document.querySelector('.login__form')
const formMdp = document.querySelector('.gestion__form--mdp')
const formInfos = document.querySelector('.gestion__form--infos')
const formForgotPass = document.querySelector('.forgotPass')
const formResetPass = document.querySelector('.forgotPass--form')
const logoutBtn = document.querySelector('#logout')
const modifArticleForm = document.querySelector('#modifArticle__form')
const createArticleForm = document.querySelector('#createArticle__form')
const suppressionArticleBtn = document.querySelector('#deleteArticle')
const deleteImgArticle = document.querySelector('.btn-delete-cross')

if(formClient){
    formClient.addEventListener('submit',e=>{
        e.preventDefault()
        const name = document.getElementById('name').value 
        const email = document.getElementById('email').value
        const subject = document.getElementById('subject').value
        const message = document.getElementById('message').value
        const tel = document.getElementById('tel').value
        document.getElementById('spinner').classList.add('spinner')
        document.querySelector('.spinner-content').classList.add('spin-wrapper')
        sendMailClient(name,email,subject,message,tel)
    })
}

if(menuServices){
    menuServices.addEventListener('click',e=>{
        menuListServices.classList.toggle("show")
        menuListServices.style.setProperty('animation-name', 'moveInBottom');
        menuListServices.style.setProperty('animation-duration', '1s');
        menuListServices.style.setProperty('animation-timing-function', 'ease-out');
    
    })
}
if(menu){
    const btn = document.querySelector('.navigation__button')
    btn.addEventListener('click',()=>{
        menu.classList.toggle('u-transform')
    })
}

if(formLogin){
    const btnLogin = document.getElementById('login')
    btnLogin.addEventListener('click',e=>{
        e.preventDefault()
        const email = document.getElementById('email').value
        const password = document.getElementById('password').value
        login(email,password)
    })
}

if(logoutBtn){
    logoutBtn.addEventListener('click',logout)
}

if(formMdp){
    const btnMdp = document.getElementById('btn__mdp')
    btnMdp.addEventListener('click',async e=>{
        e.preventDefault()
        const currentPass = document.getElementById('currentPass').value
        const newPass1 = document.getElementById('newPass1').value
        const newPass2 = document.getElementById('newPass2').value
        await updateSettings(
            {currentPass,newPass1,newPass2},
            'Mot de passe'
        )
    })
}

if(formInfos){
    const btnInfos = document.getElementById('btn__infos')
    btnInfos.addEventListener('click',async e=>{
        e.preventDefault()
        const email = document.getElementById('modifEmail').value
        await updateSettings(
            {email},
            "Adresse email"
        )
    })
}

if(formForgotPass){
    const btnForgotPass = document.getElementById('forgotPass')
    btnForgotPass.addEventListener('click',async e=>{
        e.preventDefault()
        //const email = document.getElementById('email').value
        await forgotPass()
    })
}
if(formResetPass){
    const btnResetPass = document.getElementById('ResetPass')
    btnResetPass.addEventListener('click',async e=>{
        e.preventDefault()
        const tabUrl = window.location.href.split('/')
        const token = tabUrl[4]
        const password = document.getElementById('password').value
        const passwordConfirm = document.getElementById('passwordConfirm').value
        console.log(tabUrl,token)
        await resetPassword(token,password,passwordConfirm)
    })
}
var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];
  

var options = {
    modules: {
      toolbar: toolbarOptions
    },
    theme: 'snow'
  };
  var editor = new Quill('#editor', options);
if(document.getElementById('nonEdit')){
    const edit = document.querySelectorAll('.ql-editor')
    for(let i=0;i<edit.length;i++){
        edit[i].setAttribute('contenteditable',false)
    }
}
if(document.querySelector('.navigation-secondary__list')){
    console.log(document.querySelectorAll('.ql-editor'))
    const edit = document.querySelectorAll('.ql-editor')
    for(let i=1;i<edit.length;i++){
        edit[i].classList.add('paragraph')
        edit[i].classList.add('navigation-secondary__text')
        edit[i].classList.add('text-article-small')
    }
}
if(modifArticleForm){
    const btnModif = document.getElementById('modifArticle')
    btnModif.addEventListener('click',e=>{
        e.preventDefault()
        const data = new FormData()
    
        if(document.getElementById('imgHeader')){
            data.append('imgHeader',document.getElementById('imgHeader').files[0])
        }
        if(document.getElementById('titre')){
            data.append('titre',document.getElementById('titre').value)
        }
        if(document.getElementById('editor')){
            data.append('article',document.querySelector('#editor').innerHTML)
        }
        if(document.getElementById('img')){
            for(let i=0;i<document.getElementById('img').files.length;i++){
                data.append('img',document.getElementById('img').files[i])
            }
        }
        const id = document.querySelector('#modifArticle').dataset.articleId 
        modifArticle(data,id)
    })
}

if(createArticleForm){
    const createArticleBtn = document.querySelector('#createArticle')
    createArticleBtn.addEventListener('click',e=>{
        
        e.preventDefault()
        const data = new FormData()
    
        if(document.getElementById('imgHeader')){
            data.append('imgHeader',document.getElementById('imgHeader').files[0])
        }
        if(document.getElementById('titre')){
            data.append('titre',document.getElementById('titre').value)
        }
        if(document.getElementById('editor')){
            data.append('article',document.querySelector('#editor').innerHTML)
        }
        if(document.getElementById('img')){
            for(let i=0;i<document.getElementById('img').files.length;i++){
                data.append('img',document.getElementById('img').files[i])
            }
        }
        if(document.getElementById('langue')){
            data.append('langue',document.getElementById('langue').value)
        }
        createArticle(data)
    })
}

if(document.querySelector('.test-slider')){
    $(document).ready(function(){
        console.log('ICI')
        $('.test-slider').slick({
            centerMode: true,
      centerPadding: '60px',
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
        });
    });
}

if(suppressionArticleBtn){
    const listBtn = document.querySelectorAll('#deleteArticle')
    for(let i =0;i<listBtn.length;i++){
        listBtn[i].addEventListener('click',e=>{
            e.preventDefault()
            const id = listBtn[i].dataset.articleId
            document.querySelector('.gestionArticles__modal').style.display="block"
            document.querySelector('#delArt').addEventListener('click',e=>{
                e.preventDefault()
                deleteArticle(id)
            })
        })
    }
}

/* if(deleteImgArticle){
    console.log('ICI')
    deleteImgArticle.addEventListener('click',e=>{
        console.log("LA")
        e.preventDefault()
        const id = deleteImgArticle.dataset.imgId
        const imgName = deleteImgArticle.dataset.imgName
        deleteImg(id,imgName)
    })
} */