import axios from 'axios'
import {showAlert} from './alert'

//Avec API type is either password or data 
export const updateSettings = async(data,type)=>{
    try{
        const url = type === 'Mot de passe' ? '/api/v1/users/updateMyPassword' :'/api/v1/users/emailUpdate'
        const res = await axios({
            method:'PATCH',
            url,
            data 
        })
        if(res.data.status === 'success'){
            showAlert('success',`${type.toUpperCase()} mis à jour avec succès ! `)
            window.setTimeout(()=>{
                location.assign('/accueilAdmin')
            },1500)
        }
    }catch(err){
        showAlert('error',err.response.data.message)
    }
}